import React from 'react';
import Facebook from '../component/iconsSvg/facebook';
import Twitter from '../component/iconsSvg/twitter';
import Google from '../component/iconsSvg/google';
import LinkedInIcon from '../component/iconsSvg/linkedin';
import YouTubeIcon from '../component/iconsSvg/youtube';

const Footer = () => {
  return (
    <div className="footer">
      <div className="media_links">
        <div className="icons">
          <Facebook />
          <Twitter />
          <Google />
          <LinkedInIcon />
          <YouTubeIcon />
        </div>
      </div>
      <div className="copy_right">
        Copyright &copy; 2024; Designed By "EDUVERSE Team"
      </div>
    </div>
  );
};

export default Footer;
