import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProfileSettings.css";
import { useNavigate } from "react-router-dom";
import Header from "../component/Header";

function ProfileSettings() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("User Name");
  const [profileData, setProfileData] = useState({
    fullName: "",
    email: "",
    dob: "",
    password: "",
  });
  const [formData, setFormData] = useState({
    fullName: "",
    dob: "",
    password: "",
  });
  const [isEditing, setIsEditing] = useState({
    fullName: false,
    dob: false,
    password: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("username");
    const token = localStorage.getItem("token");
    if (user && token) {
      setIsLoggedIn(true);
      setUsername(user);
      fetchProfileData(token);
    }
  }, []);

  const fetchProfileData = async (token) => {
    try {
      const response = await axios.get("http://13.53.83.119/api/user/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfileData(response.data);
      setFormData({
        fullName: response.data.fullName,
        dob: response.data.dob.split("T")[0], // Remove time part
        password: "",
      });
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: !isEditing[field] });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put("http://13.53.83.119/api/user/profile", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfileData(response.data);
      setIsEditing({
        fullName: false,
        dob: false,
        password: false,
      });
      // Update username in localStorage if it has changed
      if (formData.fullName !== profileData.fullName) {
        localStorage.setItem("username", formData.fullName);
        setUsername(formData.fullName);
      }
      console.log("Data saved:", response.data);
    } catch (error) {
      console.error("Failed to save profile data", error);
    }
  };

  return (
    <>
      <Header 
        isLoggedIn={isLoggedIn} 
        username={username} 
        handleLogout={handleLogout} 
        handleSettings={handleSettings} 
      />
      <div className="block" id="key">
        <div className="head">
          <h2>PROFILE INFO</h2>
        </div>
        <div className="profile-info-content">
          <div className="profile-info-item">
            <label>FULL NAME</label>
            {isEditing.fullName ? (
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            ) : (
              <span>{profileData.fullName}</span>
            )}
            <button onClick={() => handleEdit("fullName")}>EDIT</button>
          </div>
          <div className="profile-info-item">
            <label>DATE OF BIRTH</label>
            {isEditing.dob ? (
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
              />
            ) : (
              <span>{formData.dob}</span> // Display formatted date
            )}
            <button onClick={() => handleEdit("dob")}>EDIT</button>
          </div>
          
          <div className="profile-info-item">
            <label>PASSWORD</label>
            {isEditing.password ? (
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            ) : (
              <span>******************</span>
            )}
            <button onClick={() => handleEdit("password")}>EDIT</button>
          </div>
          <div className="profile-info-item">
            <label>EMAIL</label>
            <span className="display_email">{profileData.email}</span>
            <button></button>
          </div>
          <button className="save-button" onClick={handleSave}>
            SAVE
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileSettings;
