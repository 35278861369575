import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo-removebg-preview.png';

const Header = ({ isLoggedIn, username, handleLogout, handleSettings }) => {
  return (
    <div className="header">
      <div className="logo_parent">
        <a href="/">
          <img src={logo} alt="logo" className="logo" />
        </a>
      </div>
      <div className="nav">
        <a href="/download">download edu verse</a>
        {isLoggedIn ? (
          <div className="user-menu">
            <span>{username}</span>
            <div className="dropdown-menu">
              <button onClick={handleSettings}>Settings</button>
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        ) : (
          <Link to="/signin">Sign In</Link>
        )}
      </div>
    </div>
  );
};

export default Header;
