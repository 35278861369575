import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo-removebg-preview.png";
import download_image from "../images/download.png";
import "./DownloadPage.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
function DownloadPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("User Name");
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("username");
    if (user) {
      setIsLoggedIn(true);
      setUsername(user);
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleDownload = () => {
    window.location.href = "http://13.53.83.119/api/download";
  };

  return (
    <>
      <Header 
        isLoggedIn={isLoggedIn} 
        username={username} 
        handleLogout={handleLogout} 
        handleSettings={handleSettings} 
      />
      <div className="download-page-container">
        <div className="download-page-content">
          <div className="text-section">
            <h1>The Edu verse is now available</h1>
            <p>
              Connect with your colleagues with avatars and immersive 3D spaces.
            </p>
            <button className="download-app-button" onClick={handleDownload}>
              DOWNLOAD THE EDUVERSE APP FOR DESKTOP
            </button>
          </div>
          <div className="image-section">
            <img
              src={download_image}
              alt="Edu Verse illustration"
              className="illustration-image"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DownloadPage;
